<template>
  <!--<span>{{ hour? hourString+':'+minuteString+':'+secondString : minuteString+':'+secondString }}</span>-->
  <!-- <span>{{ hour || minute || second ? hourString+':'+minuteString+':'+secondString : '' }}</span> -->
  <div class="timer-containter">
    <div class="timer-title">开奖倒计时</div>
    <div class="timer">
      <div class="timer-item h">
        <span>{{ hour ? hourString : '00' }}</span>
      </div>
      <div class="timer-split">:</div>
      <div class="timer-item m">
        <span>{{ minute ? minuteString : '00' }}</span>
      </div>
      <div class="timer-split">:</div>
      <div class="timer-item s">
        <span>{{ second ? secondString : '00' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    remainTime: {
      // 倒计时间总秒数
      default: ''
    }
  },
  data() {
    return {
      hour: '',
      minute: '',
      second: '',
      promiseTimer: ''
    };
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour);
    },
    minuteString() {
      return this.formatNum(this.minute);
    },
    secondString() {
      return this.formatNum(this.second);
    }
  },
  mounted() {
    console.log('总秒数：' + this.remainTime);
    if (this.remainTime > 0) {
      this.hour =
        Math.floor((this.remainTime / 3600) % 24) +
        Math.floor(this.remainTime / 3600 / 24) * 24;
      this.minute = Math.floor((this.remainTime / 60) % 60);
      this.second = Math.floor(this.remainTime % 60);
      this.countDown();
    }
  },
  methods: {
    countDown() {
      var self = this;
      clearInterval(this.promiseTimer);
      this.promiseTimer = setInterval(function() {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59;
            self.minute -= 1;
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0;
            self.$emit('countDownEnd', true);
            clearInterval(self.promiseTimer);
          } else {
            self.second -= 1;
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59;
            self.minute -= 1;
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1;
            self.minute = 59;
            self.second = 59;
          } else {
            self.second -= 1;
          }
        }
      }, 1000);
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num;
    }
  }
};
</script>
<style lang="scss" scoped>
.timer-containter,
.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1ab4ab;
  font-weight: bold;
  .timer-item {
    margin: 0 2px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    box-shadow: rgb(26, 180, 171) 0px 0px 5px inset;
    border: 1px solid rgba(26, 180, 171, 0.6);
    transition: all 0.3s ease;
  }
}
</style>

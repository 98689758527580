<template>
  <div class="home-containter">
    <header>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=0.6, maximum-scale=1.0, user-scalable=0"
      />
      <i class="go-back-btn el-icon-arrow-left" @click="goBack" />
      <div id="title" class="title-containter">
        <el-tooltip
          v-model="ifShowTitleTip"
          :manual="true"
          :hide-after="1000"
          class="item"
          effect="light"
          :content="topic"
          placement="bottom"
        >
          <span class="title">{{ topic }}</span>
        </el-tooltip>
      </div>
      <!-- 标题轮播，先去掉 -->
      <!-- <Publicity v-show="!running" /> -->
      <el-button class="btn-detail" type="text" @click="getDetail">
        开奖详情
      </el-button>
      <!-- <el-button class="con" type="text" @click="showConfig = true">
        抽奖配置
      </el-button> -->
    </header>
    <div class="sec-title">
      <div v-if="running" class="prizeInfo">
        <p>
          {{ prizeInfo.grade }} — {{ prizeInfo.prizeName }} x
          {{ prizeInfo.balance }} 份
        </p>
      </div>
      <div v-else class="prizeInfo end">
        <p>
          活动已结束, 查看
          <span class="prizeInfo-btn" @click="getDetail">更多开奖详情</span>
        </p>
      </div>
      <count-down-view
        v-if="running"
        :key="countDown"
        :remain-time="countDown"
        class="count-down"
        @countDownEnd="countDownEnd"
      />
    </div>
    <!-- 抽奖小球球 -->
    <div id="main" :class="{ mask: showRes }">
      <div v-if="datas" id="tags">
        <ul v-for="item in datas" :key="item.key">
          <li>
            <a
              href="javascript:void(0);"
              :style="{
                color: '#fff'
              }"
            >
              {{ item.name ? item.name : item.key }}
              <img
                v-if="item.photo"
                :src="item.photo"
                :width="50"
                :height="50"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escap="false"
      class="resDialog"
      :visible.sync="showRes"
      width="90%"
      :title="luckUserTitle + '开奖结果'"
      :before-close="closeResDialog"
    >
      <div class="resbox">
        <el-input
          v-model="keyword"
          size="small"
          placeholder="请输入成员编号/昵称查找"
          prefix-icon="el-icon-search"
          :maxlength="10"
          :clearable="true"
          @change="selectUser"
        />
        <div class="resbox-container">
          <div
            v-for="item in hendledLuckUsers"
            :key="item.number + ''"
            class="e-item"
          >
            <div class="e-img">
              <!--<img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1907773663,546655544&fm=11&gp=0.jpg" alt="">-->
              <img :src="item.photo" alt="" />
            </div>
            <div class="e-info">
              <span class="name">{{ item.nickName }}</span
              >(<span class="cardid">{{ item.number }}</span
              >)
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="tooltip-containter">
      <el-button class="audio" plain type="text" @click="playAudio">
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <img
          class="iconfont"
          :src="[
            audioPlaying
              ? require('@/assets/voiceno.png')
              : require('@/assets/voice.png')
          ]"
          :alt="[audioPlaying ? 'stop' : 'play']"
        />
      </el-button>
      <span class="audio-info">
        <i class="el-icon-thumb" />
        {{ audioPlaying ? '开静音' : '开声音' }}
      </span>
      <el-tooltip
        v-if="running"
        :hide-after="1000"
        class="item"
        effect="light"
        content="只展示部分参与者信息，更多信息请跳转「开奖详情」"
        placement="left"
      >
        <el-button plain @click="refreshData">刷新</el-button>
      </el-tooltip>
    </div>

    <audio
      id="audiobg"
      ref="audioPrize"
      preload="auto"
      controls
      autoplay
      loop
      @play="playHandler"
      @pause="pauseHandler"
    >
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <source :src="audioSrc" />
      你的浏览器不支持audio标签
    </audio>
  </div>
</template>
<script>
import bgaudio from '@/assets/bg.mp3';
// import beginaudio from '@/assets/begin.mp3'
import prizeAudio from '@/assets/open.mp3';
import CountDownView from '@/components/CountDown';
// import { luckydrawHandler } from '@/helper/algorithm'
import {
  getUrlKey,
  // randomNum,
  getGMT8Date
} from '@/apis/utils';
import {
  getPrize,
  getActivity,
  // getJoinUser,
  // getImageUrl,
  getLuckUser,
  getRandomUser
} from '@/apis/activity';

export default {
  name: 'Home',
  components: { CountDownView },
  data() {
    return {
      ifShowTitleTip: false,
      topic: '「帅张和他的朋友们」抽奖活动', // 大标题
      running: false, // 是否开启抽奖状态，默认false
      showRes: false, // 显示抽奖结果的时候，遮罩模糊
      nowConfig: {
        name: '', // 奖品名称
        createTime: '1596547473000',
        userList: [
          // {
          //   photo: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
          //   name: '小红'
          // }
        ],
        count: 200 // 奖品数量
      },
      datashowInterval: null,
      datas: [],
      startIndex: 0,
      endIndex: 0,
      maxNum: 60,
      audioSrc: bgaudio,
      audioPlaying: true,
      activityInfo: {}, // 保存活动信息
      countDown: 0, // 开奖剩余毫秒数
      prizeInfo: {}, // 保存奖品信息
      countDownInterval: null, // 开奖倒计时器
      users: [], // 抽奖用户列表
      ranNum: 50, // 随机制作动画的用户数
      luckUsers: [
        // 单个奖品的中奖用户列表
        // {
        //   nickName: 'test1', // 用户的星球昵称
        //   number: '65345366' // 用户的星球编号
        // }, {
        //   nickName: 'test2',
        //   number: '665346'
        // }
      ],
      hendledLuckUsers: [],
      keyword: '', // 中奖名单搜索
      luckUserTitle: '', // 中奖名单的title
      myTagCanvas: {}
    };
  },

  computed: {},
  watch: {},
  created() {
    // for (let i = 0; i < 100; i++) {
    //   this.nowConfig.userList.push({
    //     name: '小' + i,
    //     avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
    //   })
    // }
  },
  mounted() {
    this.startTagCanvas();
    this.audioSrc = bgaudio;
    window.addEventListener('resize', this.reportWindowSize);
    this.startIndex = 0;
    this.endIndex = this.startIndex + this.maxNum;

    // 从url中获取活动相关参数
    const activeId = getUrlKey('activeId');
    console.log('activeId=' + activeId);

    const titleDom = document.getElementById('title');
    titleDom.addEventListener('touchstart', e => {
      e.preventDefault();
      if (this.topic && this.topic.length > 20) {
        this.ifShowTitleTip = true;
      }
      // console.log('touchstart event!')
    });
    titleDom.addEventListener('touchend', e => {
      e.preventDefault();
      setTimeout(() => {
        this.ifShowTitleTip = false;
      }, 2000);
      // console.log('touchend event!')
    });

    // 获取活动信息
    this.getActivityInfo(activeId);
  },
  beforeDestroy() {
    clearInterval(this.datashowInterval);
    window.removeEventListener('resize', this.reportWindowSize);
  },
  methods: {
    goBack() {
      // 返回上一页
      this.$router.push({ path: '/', query: { ts: Date.now() } });
    },
    getActivityInfo(activityId) {
      getActivity(activityId)
        .then(res => {
          var data = this.handleResponse(res);
          if (data.code === 200) {
            data = data.data;
            this.activityInfo = data;
            this.topic = data.activityName;
            if (this.topic && this.topic.length > 20) {
              this.ifShowTitleTip = true;
              console.log('-=-');
              setTimeout(() => {
                this.ifShowTitleTip = false;
              }, 2000);
            }
            // 开始执行时间校验
            this.dateTimeCheck();
          }
          // console.log('activityInfo:', data)
        })
        .catch(err => {
          this.$message({
            message: '查询活动信息出错啦！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    },
    sortDesc(array, key) {
      return array.sort(function(a, b) {
        const time1 = a[key];
        const time2 = b[key];
        const x = Date.parse(time1.replace(/-/g, '/'));
        const y = Date.parse(time2.replace(/-/g, '/'));
        return y > x ? -1 : x > y ? 1 : 0; // 从小到大排序
        // return ((y > x) ? 1 : (x > y) ? -1 : 0) // 从大到小排序
      });
    },
    // 根据时间判断活动状态并进行下一步操作
    dateTimeCheck() {
      // if (this.activityInfo.status === 0) {
      //   // 未开始
      //   this.$message({
      //     message: '球友莫急，活动尚未开始！',
      //     type: 'warning',
      //     duration: 5000,
      //     center: true,
      //     offset: 100
      //   })
      //   return
      // }
      if (this.activityInfo.status === 2) {
        // 已结束
        this.$message({
          message: '此活动已结束，敬请期待下次活动！',
          customClass: 'ballMsg',
          type: 'warning',
          duration: 3000,
          center: true,
          offset: 300
        });
        return;
      }
      let miss = true;
      const _this = this;
      // 遍历查找正在进行中的奖品(注意服务器返回的奖品顺序，如果不是按照时间正序排序，需要重新进行排序)
      this.activityInfo.prizes = this.sortDesc(
        this.activityInfo.prizes,
        'openTime'
      );
      // const now = Date.now()
      const now = getGMT8Date();
      this.activityInfo.prizes.every(function(e) {
        const flag = now < Date.parse(e.openTime.replace(/-/g, '/'));
        if (flag) {
          miss = false;
          _this.getPrizeInfo(e.id);
          console.log('奖品ID：' + e.id);
          return false;
        }
        return true;
      });
      if (miss) {
        if (this.running) {
          this.running = false;
          // 所有奖品开奖结束
          this.showRes = false;
          // 清空球球数据 和 奖品数据
          this.prizeInfo = {};
          this.datas = [];
          // 回到原始状态
          const audio = document.querySelector('#audiobg');
          this.$refs.audioPrize.src = bgaudio;
          audio.play();
          window.TagCanvas.Resume('rootcanvas');
          window.TagCanvas.SetSpeed('rootcanvas', [0.15, 0.1]);
          const temp = setTimeout(() => {
            window.TagCanvas.Reload('rootcanvas');
            clearTimeout(temp);
          }, 0);
        }
        this.$message({
          message: '本次活动奖品均已开奖！',
          customClass: 'ballMsg',
          type: 'warning',
          duration: 3000,
          center: true,
          offset: 300
        });
      } else {
        this.loadRandomJoinUser();
      }
    },
    // 获取奖品信息
    getPrizeInfo(prizeId) {
      getPrize(prizeId)
        .then(res => {
          var data = this.handleResponse(res);
          if (data.code === 200) {
            this.prizeInfo = data.data;
            this.countDown = parseInt(
              (Date.parse(this.prizeInfo.openTime.replace(/-/g, '/')) -
                getGMT8Date()) /
                1000
            );
            if (this.running) {
              // 新的一轮
              const audio = document.querySelector('#audiobg');
              audio.pause();
              audio.currentTime = 0;
              this.$refs.audioPrize.src = bgaudio;
              audio.play();
              window.TagCanvas.Resume('rootcanvas');
              window.TagCanvas.SetSpeed('rootcanvas', [0.15, 0.1]);
            } else {
              this.running = true;
            }
          }
        })
        .catch(err => {
          this.$message({
            message: '查询奖品信息出错啦！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    },
    // 获取动画展示用户名单
    async loadRandomJoinUser() {
      await getRandomUser({
        activityId: this.activityInfo.id,
        randomSize: this.ranNum
      })
        .then(res => {
          let data = this.handleResponse(res);
          if (data.code === 200) {
            data = data.data;
            this.users = [];
            this.users = this.users.concat(data);
          } else {
            this.$message({
              message: '查询抽奖名单失败，请稍后再试',
              customClass: 'ballMsg',
              type: 'error',
              duration: 3000,
              center: true,
              offset: 300
            });
          }
        })
        .catch(err => {
          this.$message({
            message: '查询抽奖名单出错啦！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
      if (this.users.length === 0) {
        this.$message({
          message: '本次活动无人参与！',
          customClass: 'ballMsg',
          type: 'warning',
          duration: 3000,
          center: true,
          offset: 300
        });
      } else {
        this.getUserImg();
      }
    },
    getUserImg() {
      const _this = this;
      _this.nowConfig.userList = [];
      const ranUser = this.users;
      ranUser.forEach(function(e) {
        _this.nowConfig.userList.push({
          // name: user.nickName,
          photo: 'http://api.prize.hukp.cn/' + e + '.png'
        });
      });
      this.reloadTagCanvas();
    },
    // 倒计时结束
    countDownEnd() {
      // 1.切换音乐  2.加速旋转之后停止旋转 3.弹出中奖名单提示框
      console.log('倒计时结束，开奖！！！');
      // 关闭抽奖结果标签
      this.showRes = false;
      this.luckUserTitle = this.prizeInfo.prizeName;
      const audio = document.querySelector('#audiobg');
      audio.pause();
      audio.currentTime = 0;
      // this.audioSrc = prizeAudio
      this.$refs.audioPrize.src = prizeAudio;
      audio.play();
      window.TagCanvas.SetSpeed('rootcanvas', [0.8, 0.8]);
      const _this = this;
      let hasLuck = false;
      // 请求中奖名单
      getLuckUser({
        activityId: _this.activityInfo.id,
        luckCode: _this.prizeInfo.luckCode,
        pageNo: 1,
        pageSize: 50
      })
        .then(res => {
          let data = this.handleResponse(res);
          if (data.code === 200) {
            data = data.data;
            if (data.length > 0) {
              hasLuck = true;
              const temp = data;
              let count = 0;
              data.forEach(function(e) {
                e.photo = 'http://api.prize.hukp.cn/' + e.number + '.png';
                count++;
                if (count === temp.length) {
                  _this.luckUsers = temp;
                  _this.hendledLuckUsers = temp;
                }
              });
            }
          } else {
            this.$message({
              message: '查询中奖名单失败',
              customClass: 'ballMsg',
              type: 'error',
              duration: 3000,
              center: true,
              offset: 300
            });
          }
        })
        .catch(err => {
          this.$message({
            message: '查询中奖名单出错啦！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });

      audio.loop = false;
      audio.addEventListener('ended', function() {
        // 开奖音乐播放结束
        console.log('开奖音乐播放结束');
        audio.loop = true;
        audio.src = bgaudio;
        if (hasLuck) {
          _this.showRes = true;
        } else {
          _this.$message({
            message: '本轮没有用户中奖',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
        }
        audio.pause();
        window.TagCanvas.Pause('rootcanvas');

        // 5秒之后开启下一轮抽奖
        const nextTurn = setTimeout(() => {
          console.log('开始下一轮');
          _this.dateTimeCheck();
          clearTimeout(nextTurn);
        }, 5000);
      });
      audio.play();
    },
    refreshData() {
      this.loadRandomJoinUser();
    },
    closeResDialog() {
      this.showRes = false;
      console.log('关闭结果弹窗');
    },
    // 点击查看抽奖详情，弹出抽奖列表
    getDetail() {
      location.href = 'http://front.prize.hukp.cn/?id=' + this.activityInfo.id;
    },

    reportWindowSize() {
      const AppCanvas = this.$el.querySelector('#rootcanvas');
      if (AppCanvas.parentElement) {
        AppCanvas.parentElement.removeChild(AppCanvas);
      }
      this.startTagCanvas();
    },
    playHandler() {
      this.audioPlaying = true;
    },
    pauseHandler() {
      this.audioPlaying = false;
    },
    playAudio() {
      this.audioPlaying = !this.audioPlaying;
      if (this.audioPlaying) {
        this.$el.querySelector('#audiobg').play();
      } else {
        this.$el.querySelector('#audiobg').pause();
      }
    },
    loadAudio() {
      this.$el.querySelector('#audiobg').load();
      this.$nextTick(() => {
        this.$el.querySelector('#audiobg').play();
      });
    },

    speed() {
      return [0.15, 0.1];
      // return [0.1 * Math.random() + 0.01, -(0.1 * Math.random() + 0.01)]
    },
    createCanvas() {
      const canvas = document.createElement('canvas');
      canvas.width = document.body.offsetWidth;
      canvas.height = document.body.offsetHeight;
      canvas.id = 'rootcanvas';
      this.$el.querySelector('#main').appendChild(canvas);
    },
    startTagCanvas() {
      this.createCanvas();
      const { speed } = this;
      const width = document.body.offsetWidth;
      const height = document.body.offsetHeight;
      window.TagCanvas.Start('rootcanvas', 'tags', {
        // textColour: null,
        textColour: '#58e480',
        initial: speed(),
        dragControl: 1,
        textHeight: 20,
        noSelect: true,
        lock: 'xy',
        offsetY: width > height ? -75 : 0,
        zoom: width > height ? 0.9 : 1
      });
    },
    reloadTagCanvas() {
      this.datas.length = 0;
      this.datas = this.datas.concat(this.nowConfig.userList);
      this.datashowInterval = setInterval(() => {
        window.TagCanvas.Reload('rootcanvas');
        clearInterval(this.datashowInterval);
      }, 0);
    },
    closeRes() {
      this.showRes = false;
    },
    selectUser() {
      // 搜索查询中奖用户信息
      console.log(this.keyword);
      if (!this.keyword || this.keyword === '') {
        this.hendledLuckUsers = this.luckUsers;
      } else {
        this.hendledLuckUsers = this.luckUsers.filter(item => {
          return (
            (item.nickName && item.nickName.includes(this.keyword)) ||
            (item.number && item.number.toString().includes(this.keyword))
          );
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.home-containter {
  background-image: url('../../assets/bg1.jpg');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgb(6, 12, 12);
  header {
    // background-color: #1ab4ab;
    background: transparent;
    box-shadow: 0 3px 4px rgba(255, 255, 255, 0.2);
    // height: 5em;
    min-height: 5em;
    display: flex;
    align-items: center;
    line-height: 5em;
    position: relative;
  }
  .tooltip-containter {
    position: absolute;
    top: 200px;
    right: 40px;
    width: 50px;
    box-sizing: border-box;
  }
}
header {
  .btn-detail {
    color: #fff;
    position: absolute;
    font-size: 20px;
    cursor: pointer;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      color: #1ab4ab;
      font-weight: bold;
    }
  }
  .title-containter {
    margin: 0 auto;
    padding: 10px 100px;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    text-align: center;
    // color: #1ab4ab;
    color: #1ab4ab;
    font-size: 28px;
    // font-size: 30px;
    // padding: 0 30px;
    // text-align: center;
    // color: #1ab4ab;
    // font-size: 35px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .title {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 59px;
    line-height: 29px;
    word-break: break-all;
  }
  .go-back-btn {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      color: #1ab4ab;
    }
  }
}
.mask {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

#main {
  height: 100%;
}

// 右侧工具条
.tooltip-containter {
  .audio {
    text-align: center;
    .iconfont {
      position: relative;
      left: 1px;
      width: 22px;
    }
  }
  .audio-info {
    font-size: 12px;
    color: #1ab4ab;
    display: block;
    margin-top: -5px;
    margin-bottom: 5px;
  }
}

// 结果弹窗样式
.resDialog {
  .resbox {
    color: #fff;
  }
  .resbox-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-content: flex-start;
  }
  .e-item {
    margin: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    box-shadow: rgba(26, 180, 171, 0.7) 0px 0px 8px inset;
    // box-shadow: rgba(105, 173, 124, .7) 0px 0px 8px inset;
    // border-top-left-radius: 20px;
    // border-bottom-left-radius: 20px;
    box-sizing: content-box;
    padding: 5px 12px 5px 12px;
    // padding: 5px 20px 5px 10px;
    box-sizing: border-box;
    // background-image: linear-gradient(90deg, rgba(88, 228, 128, 0.3) 0%, rgba(88, 228, 128, .02) 100%);
    // background-image: linear-gradient(90deg, rgba(0, 127, 127, 0.8) 0%, rgba(0, 127, 127, .02) 100%);
    // box-shadow: 0px 0px 12px rgba(105, 173, 124,0.1);
  }
}
.e-item {
  .e-img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .e-img img {
    display: block;
    box-shadow: 0 0 10px 0 #888;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .e-info {
    line-height: 15px;
    font-size: 12px;
    color: #fff;
    .name,
    .cardid {
      transform: scale(0.9);
    }
    .cardid {
      display: inline-block;
      margin: 0 1px;
      font-weight: bold;
      // color: #58e480;
      color: #1ab4ab;
      // color: rgba(127,255,255,0.75);
    }
  }
}
// .count-down {
//   position: absolute;
//   top: 3.5em;
//   right: 1.5em;
//   width: 200px;
//   height: 100px;
//   line-height: 40px;
//   font-size: 50px;
//   color: red;
//   padding: 0;
//   text-align: center;
// }

.sec-title {
  position: relative;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  .prizeInfo {
    // position: absolute;
    // top: 1em;
    // left: 0px;
    // right: 0px;
    margin: 1em auto;
    // color: #33CCCC;
    color: #33cccc;
    font-size: 20px;
    text-align: center;
    &.end {
      color: #33cccc;
    }
  }
  .prizeInfo-btn {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #33cccc;
    }
  }
}

@media screen and (max-width: 768px) {
  .title-containter {
    max-width: 75%;
    padding: 10px 30px !important;
    .title {
      overflow: hidden;
      // display: inline-block;
      // width: 100%;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
  }
  .sec-title {
    // position: relative;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    .prizeInfo {
      // position: absolute;
      // top: 1em;
      // left: 0px;
      // right: 0px;
      margin: 1em auto;
      color: #33cccc;
      // color: #1ab4ab;
      font-size: 20px;
      text-align: center;
      &.end {
        color: #33cccc;
      }
    }
    .prizeInfo-btn {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #33cccc;
        // color: #339933;
      }
    }
  }
}
// .prizeInfo {
//   position: absolute;
//   top: 2.3em;
//   left: 0px;
//   right: 0px;
//   margin: auto;
//   color: #33CCCC;
//   font-size: 37px;
//   text-align: center;
// }
// .count-down {
//   position: absolute;
//   top: 3.5em;
//   right: 1.5em;
//   width: 200px;
//   height: 100px;
//   line-height: 40px;
//   font-size: 50px;
//   color: red;
//   padding: 0;
//   text-align: center;
// }
// .prizeInfo {
//   position: absolute;
//   top: 2em;
//   left: 0px;
//   right: 0px;
//   margin: auto;
//   color: white;
//   font-size: 37px;
//   text-align: center;
// }
// .count-down {
//   position: absolute;
//   top: 3em;
//   right: 1.5em;
//   width: 200px;
//   height: 100px;
//   line-height: 40px;
//   font-size: 50px;
//   color: red;
//   padding: 0;
//   text-align: center;
// }
</style>
<style lang="scss">
// 结果弹窗样式
.resDialog {
  .el-input {
    width: 60%;
    display: block;
    text-align: left;
    margin-left: 8px;
    margin-bottom: 5px;
  }
  .el-input__inner {
    background-color: transparent;
    color: #fff;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #33cccc;
  }
  .el-input__prefix {
    color: #fff;
  }
  .el-dialog__header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    // box-shadow: rgb(105, 173, 124) 0px 0px 5px inset;
    box-shadow: rgb(26, 180, 171) 0px 0px 5px inset;
    // background-color: #1ab4ab;
    padding: 18px 15px;
    // position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 999;
  }
  .el-dialog__body {
    overflow-y: auto;
    max-height: 500px;
    padding-top: 20px;
    // padding-top: 80px;
    text-align: center;
  }
  .el-dialog__title {
    color: #1ab4ab;
    font-weight: bold;
  }
  .el-dialog__close {
    font-weight: bold;
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #1ab4ab;
  }
  .el-dialog {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    box-shadow: 0 0 10px 0 #fff;
    max-width: 500px;
  }
}
.tooltip-containter {
  .el-button.is-plain {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 8px 12px;
    font-size: 12px;
    margin: 10px 0;
    // background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 255, 255, 0.6);
    color: #1ab4ab;
    transition: all 0.3s ease;
    &:hover {
      background-color: rgba(255, 255, 255, 0);
      border-color: #fff;
      color: #1ab4ab;
    }
    &.audio {
      padding: 2px 12px;
    }
  }
}
.ballMsg.el-message--warning {
  background-color: transparent;
  border-color: rgba(250, 236, 216, 0.8);
  box-shadow: #faecd8 0px 0px 10px inset;
}
.ballMsg.el-message--error {
  background-color: transparent;
  border-color: rgba(253, 226, 226, 0.8);
  box-shadow: #fde2e2 0px 0px 10px inset;
}
</style>
